header{
  position: absolute;
  z-index: 100;
  background-color: transparent;
  width: 100%;
}

.img-logo {
  /* width: 220px;
  height: 70px; */

  overflow: hidden;
  /* margin-top: -6px; */
}
.slide {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-image{

    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.slider-wrapper {
  position: relative;
  overflow: hidden;
}

.slider-wrapper .slick-slide > div {
  opacity: 0;
  transition: opacity 1s ease;
  height: calc(100vh - 200px);
}

.slider-wrapper .slick-slide.slick-active > div {
  opacity: 1;
  height: calc(100vh - 200px);
}

.place-content-center{
  place-content: center;
}

.slick-list{
  height: 100vh !important;
}
.footer {
  /* background-color: #f8f9fa; */
  padding: 40px 0;
  height: 100%;
  margin-top: 10rem;
}

.footer-social {
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center; 
}

.footer-social li {
  margin-right: 3rem; 
  font-size: 12px;
}

.footer-social li:last-child {
  margin-right: 0; 
}

.footer-social a {
  color: #333;
  text-decoration: none;
}
