main {
  /* min-height: 80vh; */
}

h3 {
  padding: 1rem 0;
}
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}
.landing-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  background: transparent;
}

.landing-header.scrolled {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
}

.landing-header .landing-nav-link {
  color: white !important;
  font-weight: 300;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.landing-header .landing-nav-link:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

.landing-header .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

.main-header {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}